

/*====== AJUSTES DE TAMAÑO PARA LA APP PPAL ====
 Marcamos el tamaño de la app y la referencia rem para el resto de la app
 =============================================== */
@media screen and (max-width: 22cm) { /*Till Ipad Pro Size*/
  .App {
    width :100vw;
    height: 100vh;
  }
  .TagsBuscadorContainer .Text {
    overflow: auto;
  }

/* if we want fontsize to change when input on mobile perhaps make % of container*/
:root {font-size:3.5vh;}  
}
/* On screens bigger than IPAD */
@media screen and (min-width: 22cm) {
  .App {
    width :22cm;
    height: 100vh;
  }
  .TagsBuscadorContainer .Text {
     overflow: hidden;
   }
  :root {font-size: calc(0.035*30cm);}
}
:root { overflow: hidden;}
* {             /*include padding and border when we set the with and height*/
    box-sizing: border-box;
}
body {padding: 0;  margin: 0;} /*margin no tendria que ser auto?*/

#root {  /* the root id in main page (#root index.html), not the root (:root) element of the document */
  position:relative;
  list-style: none;
  display: flex;  /*margin: auto; is not doing it, but anyways is temporal till we define for laptop */
  align-items: center;
  justify-content: center;
  background: transparent;
}

.App {
  position: relative;
  background: #48AEA7;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
/*===UTILITIES===*/
.clearfix::after {/* i think we are not using it although we float things..*/
  content: "";
  clear: both;
  display: table;
}
/*====HOME=====*/
/*=============*/

/* Refactorings  */
.TopCenter {
  position: absolute;

  width:70%;
  height: 20%;
  bottom: 60%;
  left:15%;
 }
.CenterImage {
  display: block;/*position: relative;*/
  max-width:100%;
  max-height: 100%;
  margin: auto;
 }

.RoundedButton{
  position: relative;
  /*right: 0;*/
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #FFF;
}
.RoundedButton img {
  position: absolute;
  width:50%;
  height:50%;
  margin:25% auto auto 25%;
  /*top: 19px;
  left: 24px;*/
}

/*==== Search =========*/
.Search {
  position: absolute;
  z-index: 100;
  top: 44%;
  height: 5%;
  width: 50%;
  margin-left: 25%;
  border-radius: 2px;
  background: #FFF;
  overflow:auto;
}
.Search input::placeholder {
  color: #242F3A;
}
.Search input {
  position: absolute;
  top: 20%;
  border-width: 0px;
  height: 60%;
  font-size: 0.9rem;
  width: 83%;
  left: 8%;
  outline: none;
  color: #242F3A;
  caret-color: #48AEA7;
}
.Search .searchIcon {
  position: absolute;
  /*padding-top: 1.5vh;*/
  top: 20%;
  height: 60%;/*4vh;*/
  width: 7%;/*4vw;*/
  /*top: 1vh;*/
  right: 1%;
  margin: auto;
}
.Search .uncheckIcon {
  position: absolute;
  /*padding-top: 1.5vh;*/
  top: 20%;
  height: 60%;/*4vh;*/
  width: 7%;/*4vw;*/
  /*top: 1vh;*/
  left: 1%;
  margin: auto;
}
/*===== Description ========*/
.Lema {
  /*display:block;*/
  position: absolute;
  top: 50%;
  width: 60%;
  margin-left: 20%;
}
.Lema p:first-of-type {
  margin-top: 0;
}
.Lema p {
  margin-top: 0rem;
  color: #FFF;
  text-align: center;
  font-size: 0.55rem;
  line-height: 0.9rem;
}
.Lema img {
  max-width:15%;
  margin:auto;
}
.Manual {
  z-index: 200;
  opacity:0.9;
  position: relative;
  display: block;
  top: 10%;
  width: 80%;
  height: 27%;
  margin-left: 10%;
  background:#FFF;
  overflow:auto;
}
.Manual p,h1,li {
  margin-top: 0rem;
  /*color: #FFF;*/
  text-align: left;
  font-size: 0.55rem;
  line-height: 0.57rem;
}
.Manual h1 {
  text-align: center;
}
.Manual li {
  font-size: 0.50rem;
}
.Manual img {
  display:block;
  position:sticky;top:1%;left:92%;
  width:7%;
  
}

/******** Resultados Buscador **** */

.Overlay1 {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #242F3A;
  opacity: 0.5;
}
.TagsBuscadorContainer {
  /*display: block;*/
  position: absolute;
  z-index: 100;
  top: 14%;
  margin-left: 10%;
  width: 80%;
  height: 81%;
  /*min-height: 69.565vh;*/
  overflow:auto;
}
.TagsBuscadorContainer .TagCategoria,
.TagsBuscadorContainer .TagProducto,
.TagsBuscadorContainer .TagPais {
  position: relative;
  display: inline-block;
  height:23px; /*height: 4%;*//*21px;*/
  max-width: 100%;
  background: #48AEA7;
  /*border-radius: 8px;*/
  border: 2px solid #48AEA7;
  margin-right: 1%;
  margin-top: 0%;
  color: #FFF;
  /*padding: 0 15px;*/
  /*min-width: 32%;*/
  /*overflow: scroll;*//*overflow: auto;*/
}
.TagsBuscadorContainer .TagCategoria{
  border-radius: 0px 8px 8px 0px;
}
.TagsBuscadorContainer .TagProducto{
  border-radius: 8px;
  background: #F4C1C0;
  border: 2px solid #F4C1C0;
  color: #242F3A;
  
}
.TagsBuscadorContainer .TagProducto div,
.TagsBuscadorContainer .TagCategoria div,
.TagsBuscadorContainer .TagPais div {
  height:100%;
  float:left;
}
.TagsBuscadorContainer img {
  display:block;
}
.TagsBuscadorContainer img.TagIcon {
  transform: translate(0px,2px) scale(0.8);
}
.TagsBuscadorContainer img.TagFlag {
  transform: translate(-12px,-14px) scale(0.5,0.5) ;
}
.TagsBuscadorContainer .Text {
  display: block;
  max-width:  90%;
  font-size: 17px;
  /*overflow: auto; */ /*hidden*/ /* definend on conditionals size*/
  /*text-align:left;*/
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
}
.TagsBuscadorContainer .TagPais .Text {
  max-width:  70%;
}
/*** Login ***/
.Login{
  display: block;
  z-index: 250;
  position: absolute;
  height: 7%;
  z-index: 200;
  bottom: 0.1rem;
  left: 0.1rem;

}
.Login .RoundedButton {
  position: absolute;
  top:0px;
  left:0px;
}
.Login .LoginBody {
  position: absolute;
  top:33%;
  height:77%;
}

/*** Acceder a Resultados  */

.VerResultados {
  display: none;
  position: absolute;
  z-index: 200;
  bottom: 0.1rem;
  right: 0.1rem;
  height: 7%;
  /*width: 45%*/
  /*width: 80.8vw;
  height: 54px;*/
}
.VerResultados .TextoResultados {
  position: relative;
  display: block;
  height: 100%;
  float:right;
}
.VerResultados .TextoResultados span {
  position: relative;
  display: block;
  height: 100%;
  top: 22%;/*margin:auto;*/
  font-size: 1rem;
  color: #FFF;
  font-weight: 700;
}
.VerResultados .RoundedButton {
  float:right;
}
/** Pantalla Resultados */
.Resultados {
  display: block;
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  font-size: 24px;
  overflow:auto;
  height:90%;
  width:90%;
  left:5%;
  /*width: 80.8vw;
  height: 54px;*/
}

.Tarjetas{
  display:block;
  position:absolute;
  width: 80%;
  height:80%;
  left:10%;
  top:10%;
  overflow:auto;
  
}
.Resultados .Tarjeta {
  display:block;
  position: relative;
  box-sizing: border-box;
  width: 80%;
  height: 15rem;
  padding: 20px;
  background: #242F3A;
  margin: 1rem auto;
}
.Tarjeta .Producto {
  position: absolute;
  width: 80%;
  height: 8rem;
  background: #FFF;
  top:10%;
  left:10%;

}
.Tarjeta .Producto img {
  /*position: relative;*/
  display: block;
  /*width: 100%;*/
  height:6rem;
  max-width:100%;
  margin: 1rem auto;
  /*top: 39px;
  left: 37px;*/
}
.Tarjeta .Titulo {
  position:absolute;
  height:1.1rem;
  width:90%;
  font-size: 1rem;
  color: #FFF;
  top: 10rem;left:5%;
  /*margin: auto;*/
  text-align: center;
  font-weight: 700;
  overflow:auto;
}
.Tarjeta .Origen {
  position:absolute;
  width: 50%;
  /*height:1rem;*/
  color: #FFF;
  top: 12rem;left:25%;bottom:1rem;
  font-weight: 700;
  font-size: 0.7rem;
  /*line-height: 20px;*/
  margin: auto;
}
.Tarjeta .Origen div {
  float:left;
}

.Tarjeta .Origen img {
  /*position: relative;*/
  /*display: block;
  height: 1rem;
  margin-left: auto;
  margin-right: auto;*/
  transform: translate(-12px,-12px) scale(0.5,0.5) ;
  /*top: 39px;
  left: 37px;*/
}

.tarjetaResultado{
  display:block;
  width:80%;
  /*max-height:30%;*/
}
.productoContainer {
  display:block;
  position: relative;
  /*float:left;*/
  width:100%;
  height: 150px;
}
.productoContainer img {
  height:100%;
  /*transform:translate(-200px,-220px) scale(0.25);*/
}
/** Rest */

/** they now use general classes for behaviour */
/*
.Logo {} 
.Logo img {}
.BuscadorOverlay {}
*/
/*.InitAnimation{}*/
